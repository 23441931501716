import React, { useState, useEffect } from 'react';
import './AlertPopup.css';

const AlertPopup = ({ onClose, isMobile }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({ top: 100, left: 100 });

  // PC 버전일 때만 드래그 기능 활성화
  const handleMouseDown = (e) => {
    if (!isMobile) {
      setIsDragging(true);
      setOffset({
        x: e.clientX - position.left,
        y: e.clientY - position.top,
      });
    }
  };

  const handleMouseUp = () => {
    if (!isMobile) {
      setIsDragging(false);
    }
  };

  const handleMouseMove = (e) => {
    if (!isMobile && isDragging) {
      setPosition({
        left: e.clientX - offset.x,
        top: e.clientY - offset.y,
      });
    }
  };

  return (
    <>
      {isMobile ? (
        <div className="mobile-popup">
          <div className="mobile-popup-header">
            <span>나의 알림</span>
            <button onClick={onClose} className="mobile-close-button">
              &times;
            </button>
          </div>
          <div className="mobile-popup-content">
            <div className="notification-item">
              <div className="notification-title">새로운 채용 공고</div>
              <div className="notification-time">방금 전</div>
              <div className="notification-message">
                관심 기업 '카카오'에서 새로운 채용 공고를 등록했습니다.
              </div>
            </div>
            <div className="notification-item">
              <div className="notification-title">자기소개서 첨삭 완료</div>
              <div className="notification-time">1시간 전</div>
              <div className="notification-message">
                요청하신 자기소개서 첨삭이 완료되었습니다.
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="popup"
          style={{ top: position.top, left: position.left }}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <div
            className="popup-header"
            onMouseDown={handleMouseDown}
            style={{ cursor: 'grab' }}
          >
            <span>나의 알림</span>
            <button onClick={onClose} className="close-button">&times;</button>
          </div>
          <div className="popup-content">
            <div className="notification-item">
              <div className="notification-title">새로운 채용 공고</div>
              <div className="notification-time">방금 전</div>
              <div className="notification-message">
                관심 기업 '카카오'에서 새로운 채용 공고를 등록했습니다.
              </div>
            </div>
            <div className="notification-item">
              <div className="notification-title">자기소개서 첨삭 완료</div>
              <div className="notification-time">1시간 전</div>
              <div className="notification-message">
                요청하신 자기소개서 첨삭이 완료되었습니다.
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AlertPopup;
