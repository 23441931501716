import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const VoiceRecorder = ({ onRecordingStart, onRecordingStop }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const timerRef = useRef(null);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setRecordingTime(prev => prev + 1);
    }, 1000);
  };

  const handleStartRecording = async () => {
    try {
      setIsRecording(true);
      await onRecordingStart();
      startTimer();
    } catch (error) {
      console.error('녹음 시작 오류:', error);
    }
  };

  const handleStopRecording = async () => {
    try {
      setIsRecording(false);
      clearInterval(timerRef.current);
      await onRecordingStop();
      setRecordingTime(0);
    } catch (error) {
      console.error('녹음 중지 오류:', error);
    }
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  return (
    <RecorderContainer>
      <RecordButton 
        onClick={isRecording ? handleStopRecording : handleStartRecording}
        isRecording={isRecording}
      >
        {isRecording ? '녹음 중지' : '녹음 시작'}
      </RecordButton>
      {isRecording && <RecordingTime>{formatTime(recordingTime)}</RecordingTime>}
    </RecorderContainer>
  );
};

const RecorderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const RecordButton = styled.button`
  padding: 12px 24px;
  border-radius: 25px;
  border: none;
  background-color: ${props => props.isRecording ? '#ff4444' : '#4CAF50'};
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.9;
  }
`;

const RecordingTime = styled.div`
  font-size: 1.2em;
  color: #666;
`;

export default VoiceRecorder; 