import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import styles from './LandingPage.module.css';

import { Player } from '@lottiefiles/react-lottie-player';
//이미지 import
import slidingImage1 from '../assets/images/slidingImage1.png';
import slidingImage2 from '../assets/images/slidingImage2.png';
import leftImg from '../assets/images/landingpage/herosection-leftimg.png';
import icon1 from '../assets/images/icons/icon1.png';
import aiCoverLetter from '../assets/images/landingpage/aicoverletter.png';
import photoGenerator from '../assets/images/landingpage/photo-generator.png';
import questionImg from '../assets/images/landingpage/quetionimg.png';
import testGraph from '../assets/images/landingpage/testgraph.png';
import whiteLogo from '../assets/images/logos/whitelogo.svg';
import robotIcon from '../assets/images/landingpage/roboticon.svg';
import starIcon from '../assets/images/landingpage/startsicon.svg';
import wonIcon from '../assets/images/landingpage/moneyicon.png';
import briefcaseIcon from '../assets/images/landingpage/briefcaseicon.svg';


// JSON 파일들 import
import animation1 from '../assets/images/landingpage/1.json';
import animation2 from '../assets/images/landingpage/2.json';
import animation3 from '../assets/images/landingpage/3.json';
import animation4 from '../assets/images/landingpage/4.json';

const LandingPage = () => {
  const navigate = useNavigate();
  const featureRefs = useRef([]);
  const reasonRefs = useRef([]);
  const ctaRef = useRef(null);
  const [activeExample, setActiveExample] = useState('자소서 생성기');
  const [openFAQ, setOpenFAQ] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [activeReason, setActiveReason] = useState(0);

  // handleNavigation 함수를 수정합니다.
  const handleNavigation = () => {
    navigate('/login');
  };

  const reasons = [
    { 
      icon: robotIcon, 
      title: 'AI 기반의 정확한 이력서/자소서 평가', 
      description: '200만+ 문서 분석', 
      details: '잡메이커는 AI를 통해 200만 건 이상의 이력서와 자기소개서를 분석하고 맞춤형 피드백을 제공합니다.' 
    },
    { icon: starIcon, title: '사용자들의 높은 만족도', description: '95% 이상의 만족도', details: 'AI 기술을 통해 빠르고 정확한 취업 원으로 95% 이상의 만족도를 기록했습니다.' },
    { icon: wonIcon, title: '저렴하고 합리적인 가격', description: '학생 전용 할인가 제공', details: '학생들을 위한 특별 할인과와 맞춤형 요금제로 누구나 쉽게 AI 기반 취업 솔루션을 이용할 수 있습니다.' },
    { icon: briefcaseIcon, title: '성공적인 실제 취업 사례들', description: '성공적인 취업 사례', details: '잡메이커를 통해 수많은 학생들이 원하는 직장에 취업했으며, 그들의 성공 스토리를 확인해보세요!' },
  ];

  const features = [
    { 
      title: "자기소개서 생성기", 
      description: "개인의 경험과 역량을 바탕으로 맞춤형 자기소개서를 생성하여 취업 준비의 첫걸음을 도와드립니다.",
      animation: animation1
    },
    { 
      title: "증명사진 생성기", 
      description: "AI 기술을 활용하여 전문적이고 자연스러운 증명사진을 생성합니다. 다양한 스타일과 배경 옵션을 제공하여 맞춤형 증명사진을 만들 수 있습니다.",
      animation: animation2
    },
    { 
      title: "면접 질문 생성기", 
      description: "지원한 직무와 기업에 맞는 예상 면접 질문을 생성하여 면접 준비를 철저히 할 수 있도록 돕습니다.",
      animation: animation3
    },
    { 
      title: "자기소개서 분석", 
      description: "개인의 스킬과 경험을 분석하여 가장 적합한 채용 공고를 추천하고 매칭해드립니다.",
      animation: animation4
    },
  ];

  const generationExamples = {
    '자소서 생성기': "홍길동 님이 주니어 개발자로 일하며 얻은 기술적 역량이 잘 드러난 자소서 예시입니다!\n\n저는 XYZ Corp.에서 주니어 개발자로 일하며 얻은 경험을 바탕으로, 귀사에서 더 큰 성장을 이루고 싶습니다. 특히, 최신 기술 트렌드를 따라가며 지속적으로 배우고 도전하는 자세를 유지하고자 합니다. 제 목표는 다음과 같습니다. 기술적 성장: 앞으로 3년 내에 풀스택 개발자로 성장하고, 다양한 프로젝트를 통해 기술적 역량을 강화하고자 합니다.\n이를 위해 직종 기술 세미나와 워크숍에 적극적으로 참여하고, 최신 개발 트렌드와 도구들을 습득하겠습니다.프로젝트 리더십: 주니어 개발자로서 팀 내에서 중요한 역할을 맡으며\n프로젝트 관리 능력을 키워나가겠습니다. 이를 통해 5년 내에 프로젝트 리더로서 팀을 이끌며, 효과적으로 프로젝트를 완수하는 능력을 갖추고자 합니다.",
    '증명사진 생성기': null,
    '면접 질문 생성기': null,
    '이력서 평가': null
  };

  const faqs = [
    {
      question: "잡메이커는 어떤 서비스인가요?",
      answer: "잡메이커는 AI 기술을 활용한 취업 준비 플랫폼입니다. 자기소개서 작성, 면접 준비, 이력서 평가 등 취업 준비의 전 과정을 지원합니다."
    },
    {
      question: "AI로 생성된 자소서를 그대로 써도 저작권 문제는 없나요?",
      answer: "AI가 생성한 자소서는 참고용으로 사용하시는 것이 좋습니다. 내용을 자신의 경험과 스타일에 맞게 수정하여 사용하시면 저작권 문제 없이 안전하게 사용하실 수 있습니다."
    },
    {
      question: "어떤 AI를 이용해 생성하나요?",
      answer: "잡메이커는 최신 AI 모델을 취업 분야에 특화되도록 훈련시켜 사용합니다. 이 모델은 수많은 자기소개서와 이력서 데이터로 학습되어 높은 품질의 결과물을 제공합니다."
    },
    {
      question: "이용 가격이 어떻게 되나요?",
      answer: "잡메이커는 다양한 요금제를 제공합니다. 기본적인 기능을 무료로 이용할 수 있으며, 더 많은 기능을 원하시는 경우 월 구독 또는 건당 결제 옵션을 선택하실 수 있습니다. 자세한 가격은 웹사이트의 요금제 페이지에서 확인하실 수 있습니다."
    },
    {
      question: "청소년도 잡메이커를 이용할 수 있나요?",
      answer: "네, 물론입니다! 잡메이커는 청소년들의 진로 탐색과 자기소개서 작성에도 큰 도움이 됩니다. 다만, 14세 미만 사용자의 경우 부모님의 동의가 필요할 수 있습니다."
    },
    {
      question: "AI로 생성된 답변은 모두 정확한가요?",
      answer: "AI는 학습된 데이터를 기반으로 답변을 생성하므로, 대부분의 경우 신뢰할 만한 정보를 제공합니다. 하지만 100% 완벽한 정확성을 보장하지는 않습니다. 따라서 생성된 내용을 참고하되, 최종적으로는 사용자가 검토하고 수정하는 것이 좋습니다."
    }
  ];

  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(index);
    }
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry, index) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            entry.target.classList.add(styles.fadeInUp);
          }, index * 200); // 각 아이템마다 200ms 딜레이
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    featureRefs.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });
    reasonRefs.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });
    if (ctaRef.current) observer.unobserve(ctaRef.current);

    return () => {
      featureRefs.current.forEach(ref => {
        if (ref) observer.unobserve(ref);
      });
      reasonRefs.current.forEach(ref => {
        if (ref) observer.unobserve(ref);
      });
      if (ctaRef.current) observer.unobserve(ctaRef.current);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.visible);
          }
        });
      },
      { threshold: 0.5 }
    );

    const ctaText = document.querySelector(`.${styles.textExpand}`);
    if (ctaText) {
      observer.observe(ctaText);
    }

    return () => {
      if (ctaText) {
        observer.unobserve(ctaText);
      }
    };
  }, []);

  // 생성 예시 섹션 수정
  const renderGenerationExamples = () => {
    if (isMobile) {
      return (
        <div className={styles.mobileExampleSlider}>
          <div className={styles.mobileExampleSlides} 
               style={{ transform: `translateX(-${Object.keys(generationExamples).indexOf(activeExample) * 100}%)` }}>
            {Object.entries(generationExamples).map(([key, value]) => (
              <div key={key} className={styles.mobileExampleSlide}>
                <h4 className={styles.exampleTitle}>
                  {key === '자소서 생성기' ? '홍길동 님의 자기소개서 예시' : `${key} 예시`}
                </h4>
                {key === '자소서 생성기' ? (
                  <p className={styles.exampleText}>{value}</p>
                ) : (
                  <div className={styles.exampleImagePlaceholder}>
                    {key} 이미지가 들어갈 자리입니다.
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className={styles.mobileExampleDots}>
            {Object.keys(generationExamples).map((key) => (
              <button
                key={key}
                className={`${styles.dot} ${activeExample === key ? styles.activeDot : ''}`}
                onClick={() => setActiveExample(key)}
              />
            ))}
          </div>
        </div>
      );
    }

    // PC 버전 수정
    return (
      <>
        <div className={styles.exampleTabs}>
          {Object.keys(generationExamples).map((key) => (
            <button
              key={key}
              className={`${styles.exampleTab} ${activeExample === key ? styles.activeTab : ''}`}
              onClick={() => setActiveExample(key)}
            >
              <span className={styles.tabIcon}>✦</span>
              {key}
            </button>
          ))}
        </div>
        <div className={styles.exampleContent}>
          {activeExample === '자소서 생성기' ? (
            <div className={styles.textContent}>
              <span className={styles.contentIcon}>✦</span>
              <div>
                <h4 className={styles.exampleTitle}>홍길동 님이 주니어 개발자로 일하며 얻은 기술적 역량이 잘 드러난 자소서 예시입니다!</h4>
                <p className={styles.exampleText}>{generationExamples[activeExample]}</p>
              </div>
            </div>
          ) : (
            <div className={styles.exampleImagePlaceholder}>
              {activeExample} 이미지가 들어갈 자리입니다.
            </div>
          )}
        </div>
      </>
    );
  };

  const renderReasons = () => {
    if (isMobile) {
      return (
        <div className={styles.mobileReasonSlider}>
          <div className={styles.mobileReasonSlides} 
               style={{ transform: `translateX(-${activeReason * 100}%)` }}>
            {reasons.map((reason, index) => (
              <div key={index} className={styles.mobileReasonSlide}>
                <img src={reason.icon} alt={reason.title} className={styles.reasonIcon} />
                <h4 className={styles.reasonTitle}>{reason.title}</h4>
                <div className={styles.reasonDescription}>{reason.description}</div>
                <p className={styles.reasonDetails}>{reason.details}</p>
              </div>
            ))}
          </div>
          <div className={styles.mobileReasonDots}>
            {reasons.map((_, index) => (
              <button
                key={index}
                className={`${styles.dot} ${activeReason === index ? styles.activeDot : ''}`}
                onClick={() => setActiveReason(index)}
              />
            ))}
          </div>
        </div>
      );
    }

    // PC 버전은 기존 방식 유지
    return (
      <div className={styles.reasonGrid}>
        {reasons.map((reason, index) => (
          <div key={index} className={`${styles.reasonItem} ${styles.fadeInUpItem}`} ref={el => reasonRefs.current[index] = el}>
            <img src={reason.icon} alt={reason.title} className={styles.reasonIcon} />
            <h4 className={styles.reasonTitle}>{reason.title}</h4>
            <div className={styles.reasonDescription}>{reason.description}</div>
            <p className={styles.reasonDetails}>{reason.details}</p>
          </div>
        ))}
      </div>
    );
  };

  const MobileHeroSection = () => (
    <section className={styles.mobileHeroSection}>
      <div className={styles.mobileHeroContent}>
        <img src={whiteLogo} alt="JOBMAKER" className={styles.mobileHeroLogo} />
        <h1 className={styles.mobileHeroTitle}>
          자소서 쓰기 어려운 취준생부터<br />
          이직이 궁금한 직장인까지!
        </h1>
        <p className={styles.mobileHeroDescription}>
          잡메이커는 AI를 활용한 취업도구 플랫폼입니다.<br />
          인공지능이 도와주는 새로운 취업의 장을 열어보세요!
        </p>
        <img src={icon1} alt="Right Image" className={styles.mobileHeroImage} />
        <button className={styles.mobileHeroButton} onClick={handleNavigation}>
          무료로 시작하기
        </button>
      </div>
    </section>
  );

  return (
    <div className={styles.landingPage}>
      <Header />
      <main className={styles.landingPageMainContent}>
        {isMobile ? (
          <MobileHeroSection />
        ) : (
          <section className={styles.heroSection}>
            <div className={styles.heroContent}>
              <img src={whiteLogo} alt="JOBMAKER" className={styles.ctaLogo} />
              <h1 className={styles.heroTitle}>자소서 쓰기 어려운 취준생부터<br />이직이 궁금한 직장인까지!</h1>
              <p className={styles.heroDescription}>잡메이커는 AI를 활용한 취업도구 플랫폼입니다.<br />인공지능이 도와주는 새로운 취업의 장을 열어보세요!</p>
              <button className={styles.heroButton} onClick={handleNavigation}>
                무료로 시작하기
              </button>
            </div>
            <div className={styles.heroIcons}>
              <div className={styles.heroLeftIcons}>
                <img src={leftImg} alt="Left Image" className={styles.heroLeftImage} />
              </div>
              <div className={styles.heroRightIcons}>
                <img src={icon1} alt="Icon 1" className={styles.heroRightIcon} />
              </div>
            </div>
          </section>
        )}

        {/* Sliding Images 섹션 */}
        <section className={styles.slidingImagesSection}>
          <div className={styles.container}>
            <div className={styles.slidingImages}>
              <img src={slidingImage1} alt="Sliding Image 1" />
              <img src={slidingImage2} alt="Sliding Image 2" />
            </div>
          </div>
        </section>

        {/* Features 섹션 */}
        <section className={styles.featuresSection}>
          <div className={styles.featuresContainer}>
            <h2 className={styles.featuresMainTitle}>대표기능</h2>
            <h3 className={styles.featuresSubTitle}>잡메이커가 혹독하게 준비한 AI를 활용한<br />4가지 대표기능입니다!</h3>
            <div className={styles.featuresListWrapper}>
              {features.map((feature, index) => (
                <div key={index} className={styles.featureItemCard}>
                  <div className={styles.featureItemLeftSection}>
                    <Player
                      src={feature.animation}
                      loop={true}
                      autoplay={true}
                      style={{ width: '100%', height: '100%' }}
                    />
                  </div>
                  <div className={styles.featureItemRightSection}>
                    <div className={styles.featureItemContent}>
                      <div className={styles.featureItemHeader}>
                        <span className={styles.featureItemAiTag}>Ai</span>
                        <h4 className={styles.featureItemTitle}>{feature.title}</h4>
                      </div>
                      <p className={styles.featureItemDescription}>{feature.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* 생성 예시 섹션 추가 */}
        <section className={styles.generationExamples}>
          <div className={styles.container}>
            <h2 className={styles.sectionTitle}>생성 예시</h2>
            <h3 className={styles.sectionSubtitle}>
              실제 잡메이커 AI를 활용한 생성 예시입니다!
            </h3>
            {renderGenerationExamples()}
          </div>
        </section>

        {/* Stats 섹션 */}
        <section className={styles.stats}>
          <div className={styles.container}>
            <h2 className={styles.sectionTitle}>잡메이커를 선택해야 하는 이유</h2>
            <h3 className={styles.sectionSubtitle}>
              AI가 만들어가는 취업 성공의 새로운 기준
            </h3>
            {renderReasons()}
          </div>
        </section>

        {/* FAQ 섹션 */}
        <section className={styles.faqSection}>
          <div className={styles.container}>
            <h2 className={styles.sectionTitle}>FAQ</h2>
            <h3 className={styles.sectionSubtitle}>자주 묻는 질문</h3>
            <div className={styles.faqList}>
              {faqs.map((faq, index) => (
                <div key={index} className={styles.faqItem}>
                  <div className={styles.faqQuestion} onClick={() => toggleFAQ(index)}>
                    {faq.question}
                    <span className={styles.faqToggle}>
                      {openFAQ === index ? '▲' : '▼'}
                    </span>
                  </div>
                  <div className={`${styles.faqAnswer} ${openFAQ === index ? styles.open : ''}`}>
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA 섹션 */}
        <section className={styles.ctaSection}>
          <div className={styles.ctaContainer}>
            {!isMobile && (
              <div className={styles.ctaLeftIcons}>
                <img src={leftImg} alt="Left icons" />
              </div>
            )}
            <div className={styles.ctaContent}>
              <img src={whiteLogo} alt="JOBMAKER" className={styles.ctaLogo} />
              <p className={styles.ctaText}>
                <span className={styles.textExpand}>AI가 만들어가는 새로운 취업의 시작</span>
              </p>
              <button className={styles.ctaButton} onClick={handleNavigation}>
                무료로 시작하기
              </button>
            </div>
            {!isMobile && (
              <div className={styles.ctaRightIcons}>
                <img src={icon1} alt="Right icons" />
              </div>
            )}
            {isMobile && (
              <div className={styles.mobileBgImages}>
                <img src={leftImg} alt="Background Left" className={styles.mobileBgLeft} />
                <img src={icon1} alt="Background Right" className={styles.mobileBgRight} />
              </div>
            )}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};


export default LandingPage;
