import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import { isAuthenticated, removeToken } from '../utils/auth';
import headerLogo from '../assets/images/logos/headerlogo.svg';
import { FaSignOutAlt, FaSignInAlt, FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const navigate = useNavigate();
  const authenticated = isAuthenticated();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    removeToken();
    navigate('/login');
  };

  const handleStartButton = () => {
    if (authenticated) {
      navigate('/main');
    } else {
      navigate('/signup');
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header__container">
      {/* PC 버전 헤더 */}
      <div className="header__desktop">
        <div className="header__logo">
          <Link to="/">
            <img src={headerLogo} alt="Jobmaker" />
          </Link>
        </div>
        <nav className="header__nav">
          <Link to="/main" className="header__link">홈</Link>
          <Link to="/recruit/info" className="header__link">취업 정보</Link>
          <Link to="/community" className="header__link">커뮤니티</Link>
          {authenticated && <Link to="/mypage" className="header__link">마이페이지</Link>}
        </nav>
        <div className="header__auth">
          {authenticated ? (
            <button onClick={handleLogout} className="header__link">로그아웃</button>
          ) : (
            <Link to="/login" className="header__link">로그인</Link>
          )}
          <button onClick={handleStartButton} className="header__start-button">
            {authenticated ? '메인페이지' : '시작하기'}
          </button>
        </div>
      </div>

      {/* 모바일 버전 헤더 */}
      <div className="header__mobile">
        <div className="header__mobile-top">
          <button className="header__mobile-menu-button" onClick={toggleMenu}>
            <FaBars />
          </button>
          
          <div className="header__mobile-logo">
            <Link to="/">
              <img src={headerLogo} alt="Jobmaker" />
            </Link>
          </div>

          <div className="header__mobile-auth">
            {authenticated ? (
              <button onClick={handleLogout} className="header__mobile-auth-button">
                로그아웃
              </button>
            ) : (
              <Link to="/login" className="header__mobile-auth-button">
                로그인
              </Link>
            )}
          </div>
        </div>

        {/* 모바일 메뉴 드롭다운과 오버레이 */}
        <div className={`header__mobile-menu ${isMenuOpen ? 'active' : ''}`}>
          <button className="header__mobile-close" onClick={toggleMenu}>
            <FaTimes />
          </button>
          <nav className="header__mobile-nav">
            <Link to="/main" className="header__mobile-link" onClick={toggleMenu}>홈</Link>
            <Link to="/recruit/info" className="header__mobile-link" onClick={toggleMenu}>취업 정보</Link>
            <Link to="/community" className="header__mobile-link" onClick={toggleMenu}>커뮤니티</Link>
            {authenticated && <Link to="/mypage" className="header__mobile-link" onClick={toggleMenu}>마이페이지</Link>}
            <button onClick={() => { handleStartButton(); toggleMenu(); }} className="header__mobile-start-button">
              {authenticated ? '메인페이지' : '시작하기'}
            </button>
          </nav>
        </div>
        <div className={`header__mobile-overlay ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}></div>
      </div>
    </header>
  );
};

export default Header;
