import React, { useState } from 'react';
import { FaDownload, FaCheck } from 'react-icons/fa';
import './ResultStep.css';

const ResultStep = ({ generatedPhotos, onBack }) => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [imageErrors, setImageErrors] = useState({});
  const [downloading, setDownloading] = useState(false);

  const handlePhotoSelect = (index) => {
    setSelectedPhoto(index);
  };

  const handleImageError = (index) => {
    console.error('Image load error for index:', index);
    setImageErrors(prev => ({...prev, [index]: true}));
  };

  const handleDownload = async () => {
    if (selectedPhoto === null) {
      alert('다운로드할 사진을 선택해주세요.');
      return;
    }

    try {
      setDownloading(true);
      const response = await fetch(generatedPhotos[selectedPhoto].imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `generated_photo_${selectedPhoto + 1}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download error:', error);
      alert('다운로드 중 오류가 발생했습니다.');
    } finally {
      setDownloading(false);
    }
  };

  if (!generatedPhotos || generatedPhotos.length === 0) {
    return (
      <div className="result-step">
        <div className="result-step__error">
          <h2>생성된 사진이 없습니다</h2>
          <p>오류가 발생했거나 아직 사진이 생성되지 않았습니다.</p>
          <button className="result-step__button" onClick={onBack}>
            이전 단계로 돌아가기
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="result-step">
      <h1 className="result-step__title">증명사진 생성기</h1>
      <h2 className="result-step__subtitle">결과 확인</h2>

      <div className="result-step__content">
        <div className="result-step__guide">
          <p>AI가 생성한 4장의 사진 중 마음에 드는 사진을 선택하여 다운로드하세요.</p>
          <p>각 사진은 동일한 스타일로 생성되었지만 미세한 차이가 있을 수 있습니다.</p>
        </div>

        <div className="result-step__photos-grid">
          {generatedPhotos.map((photo, index) => (
            <div 
              key={index}
              className={`result-step__photo-item ${selectedPhoto === index ? 'selected' : ''}`}
              onClick={() => handlePhotoSelect(index)}
            >
              {imageErrors[index] ? (
                <div className="result-step__image-error">
                  이미지 로드 실패
                </div>
              ) : (
                <>
                  <img 
                    src={photo.imageUrl} 
                    alt={`Generated Photo ${index + 1}`}
                    onError={() => handleImageError(index)}
                  />
                  {selectedPhoto === index && (
                    <div className="result-step__photo-selected">
                      <FaCheck />
                    </div>
                  )}
                </>
              )}
              <span className="result-step__photo-number">사진 {index + 1}</span>
            </div>
          ))}
        </div>

        <div className="step-buttons">
          <button 
            className="step-button step-button--secondary"
            onClick={onBack}
          >
            다시 생성하기
          </button>
          <button
            className={`step-button step-button--primary ${selectedPhoto === null ? 'disabled' : ''}`}
            onClick={handleDownload}
            disabled={selectedPhoto === null || downloading}
          >
            {downloading ? (
              <span className="result-step__loading">
                <span className="result-step__loading-spinner"></span>
                다운로드 중...
              </span>
            ) : (
              <>
                <FaDownload /> PNG 다운로드
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResultStep;
