import React, { useState, useEffect } from 'react';
import { CheckCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../utils/auth';
import './SubscribePlanPage.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// 가격에 맞는 토큰 계산 함수
const calculateTokens = (price, tokenRate) => Math.round(price * tokenRate);

const PlanCard = ({ title, basePrice, tokenRate, minPrice, maxPrice, features, isActive, onSubscribe, isFree }) => {
  const [price, setPrice] = useState(minPrice); // 슬라이더 초기 값 설정
  const tokens = calculateTokens(price, tokenRate); // 토큰 계산

  const handlePriceChange = (e) => {
    const newPrice = Math.round(parseInt(e.target.value) / 5000) * 5000; // 가격을 5000 단위로 변경
    setPrice(newPrice);
  };

  return (
    <div className={`plan-card ${isActive ? 'active' : ''}`}>
      {/* 추가: 플랜 이름에 스타일 추가 */}
      <div className="plan-header">
        <span className={`plan-title ${title.toLowerCase().replace(' ', '-')}`}>{title}</span>
      </div>
      <p className="price">{price.toLocaleString()}원 / 월</p>
      {isFree ? (
        <div className="token-amount">5,000 tokens</div>
      ) : (
        <div className="token-slider">
          {/* 수정: 슬라이더 아래 토큰 범위 표시 */}
          <div className="token-amount">{tokens.toLocaleString()} tokens</div>
          <input 
            type="range" 
            min={minPrice} 
            max={maxPrice} 
            value={price} 
            onChange={handlePriceChange}
          />
          <div className="slider-range">
            <span>{calculateTokens(minPrice, tokenRate).toLocaleString()} tokens</span>
            <span>{calculateTokens(maxPrice, tokenRate).toLocaleString()} tokens</span>
          </div>
        </div>
      )}
      {/* 플랜 특징 출력 */}
      <ul className="features">
        {features.map((feature, index) => (
          <li key={index}>
            <CheckCircle size={16} />
            {feature}
          </li>
        ))}
      </ul>
      {/* 버튼 클래스 이름 변경 및 스타일 수정 */}
      <button 
        className={`subscribe-btn ${isActive ? 'active' : ''}`} 
        onClick={() => onSubscribe(title, price, tokens)}
      >
        {isActive ? '이용중' : '구독하기'}
      </button>
    </div>
  );
};

const SubscribePlanPage = () => {
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState('Free'); // 기본 플랜 설정
  const token = getToken();

  // 구독 상태 조회용도
  const getSubscription = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/user/getlistcounts`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setSubscription(data.subscribePlan); // 구독 정보 상태에 저장
      } else {
        console.error('구독 정보 불러오기 실패');
      }
    } catch (error) {
      console.error('Error fetching subscription:', error);
    }
  };

  useEffect(() => {
    getSubscription(); // 마운트 시 구독 정보 불러오기
  }, []);

  // 플랜 배열: 추가된 플랜 이름 스타일 클래스 반영
  const plans = [
    {
      title: 'Free plan',
      basePrice: 0,
      tokenRate: 0,
      minPrice: 0,
      maxPrice: 0,
      features: ['베이직 툴', '5,000 tokens'],
      isActive: subscription === 'Free',
      isFree: true,
    },
    {
      title: 'Starter plan',
      basePrice: 10000,
      tokenRate: 5,
      minPrice: 5000,
      maxPrice: 30000,
      features: ['스타터 툴', '커스텀 지원'],
      isActive: subscription === 'starter plan',
      isFree: false,
    },
    {
      title: 'Professional plan',
      basePrice: 50000,
      tokenRate: 6,
      minPrice: 50000,
      maxPrice: 100000,
      features: ['프로 툴', '커스텀 지원', '전문 멘토 매칭'],
      isActive: subscription === 'professional plan',
      isFree: false,
    },
  ];

  // 구독하기 버튼 클릭 시 함수
  const handleSubscribe = async (planTitle, price, tokens, features) => {
    const token = getToken();
    if (!token) {
      alert('로그인 후 이용해주세요.');
      navigate('/login');
      return;
    }
    // 백엔드에서 빌링 정보 확인
    try {
      navigate('/payment/details', { state: { planTitle, price, tokens, features } });
    } catch (error) {
      console.error('Error checking billing info:', error);
    }
  };

  return (
    <div className="subscribe-page">
      <h2>구독정보</h2>
      <div className="subscribe-container">
        <div className="plan-cards">
          {plans.map((plan, index) => (
            <PlanCard key={index} {...plan} onSubscribe={(title, price, tokens) => handleSubscribe(title, price, tokens, plan.features)} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubscribePlanPage;