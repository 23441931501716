import React from 'react';
import { loadTossPayments } from "@tosspayments/tosspayments-sdk";    // 토스페이먼츠 sdk
import { useEffect, useState } from "react";
import { CheckCircle } from 'lucide-react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getToken } from '../../utils/auth';
import { v4 as uuidv4 } from 'uuid';  // uuid 패키지
import credit_card from '../../assets/images/icons/credit-card.png';
import './PaymentPage.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// 랜덤 20자리 문자열 생성 함수
const generateRandomString = (length = 20) => {
  // 조건에 맞는 문자 집합을 정의합니다.
  const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
  const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const digits = '0123456789';
  const specialChars = '-_=.@';

  // 각 문자 집합에서 최소 1개씩 선택해야 하므로 시작 부분에 추가
  let result = '';
  result += lowerCase.charAt(Math.floor(Math.random() * lowerCase.length));  // 소문자
  result += upperCase.charAt(Math.floor(Math.random() * upperCase.length));  // 대문자
  result += digits.charAt(Math.floor(Math.random() * digits.length));        // 숫자
  result += specialChars.charAt(Math.floor(Math.random() * specialChars.length)); // 특수문자

  // 나머지 문자는 랜덤하게 선택하여 추가
  const allChars = lowerCase + upperCase + digits + specialChars;
  for (let i = result.length; i < length; i++) {
    result += allChars.charAt(Math.floor(Math.random() * allChars.length));
  }

  // 결과 문자열이 주어진 길이보다 작다면 추가로 랜덤한 문자들을 넣음
  result = result.slice(0, length);

  // 문자열을 섞어서 반환 (문자열의 무작위성을 높이기 위해)
  return result.split('').sort(() => Math.random() - 0.5).join('');
};

const PaymentPage = () => {
  const navigate = useNavigate();
  const location = useLocation(); // 전 페이지에서 선택한 구독정보를 받아오는 변수
  const { planTitle, price, tokens, features } = location.state || {}; // location.state로부터 값 가져오기
  const [isLoading, setIsLoading] = useState(true);  // 로딩 상태
  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);  // "등록 중" 상태 관리
  const [termsAgreed, setTermsAgreed] = useState(false); // 약관 동의 상태
  const [payment, setPayment] = useState(null);   // 결제 정보 변수
  const [billingInfo, setBillingInfo] = useState(null); // 빌링 정보 변수
  const [selectedBilling, setSelectedBilling] = useState(null); // To store selected billing info
  const [userInfo, setUserInfo] = useState(null); // 사용자 정보
  const [searchParams] = useSearchParams();   // 빌링 키 발급 성공 시 url 변수 값 받아오기
  const [isRedirected, setIsRedirected] = useState(false);  // URL 파라미터가 있는지 여부 확인
  const [isreload, setReload] = useState(false);
  const [randomKey, setRandomkey] = useState(null); // 랜덤 키
  const token = getToken();

  // 페이지 로드 시 location.state로 결제 정보 저장
  useEffect(() => {
    // 새로 고침 후, 로컬 스토리지에 저장된 값이 있으면 불러오기
    const savedInfo = localStorage.getItem("subscriptionInfo");
    
    if (savedInfo) {
      // 로컬 스토리지에 저장된 값이 있으면 상태 업데이트
      setSubscriptionInfo(JSON.parse(savedInfo));
    } else {
      // location.state가 있으면 해당 값을 저장
      const { planTitle, price, tokens, features } = location.state || {};
      if (planTitle && price && tokens && features) {
        setSubscriptionInfo({ planTitle, price, tokens, features });
        // 로컬 스토리지에 저장
        localStorage.setItem("subscriptionInfo", JSON.stringify({ planTitle, price, tokens, features }));
      }
      setIsLoading(false); // 구독 정보 로딩 완료
    }
  }, [location.state]);

  // ------ 사용자 정보 가져오기 ------
  useEffect(() => {
    async function fetchUserInfo() {
      try {
        const response = await fetch(`${BACKEND_URL}/user`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'x-auth-token': token,
          },
        });

        const data = await response.json();
        if (data) {
          setUserInfo(data); // 사용자 정보 저장
        } else {
          alert("사용자 정보를 가져오는 데 실패했습니다.");
        }
      } catch (error) {
        console.error("사용자 정보 가져오기 오류:", error);
      }
    }

    if (token) {
      fetchUserInfo();
    }
  }, [token, isRedirected]);

  // ------ 등록된 카드 정보 가져오기 ------
  useEffect(() => {
    async function fetchBillingInfo() {
      try {
        const response = await fetch(`${BACKEND_URL}/billing/check-billing`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'x-auth-token': token,
          },
        });

        const data = await response.json();
        if (data.hasBillingInfo) {
          setBillingInfo(data.billingData); // 빌링 정보 저장
        } else {
          console.log("등록된 카드 정보가 없습니다");
        }
      } catch (error) {
        console.error("빌링 정보 확인 오류:", error);
      }
    }
    fetchBillingInfo();
  },[token, isRedirected, isreload]);

  // ------ ClientKey, CustomerKey 정의 ------
  const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY;

  // 빌링 정보 섹션
  useEffect(() => {
    if (selectedBilling) {
      // 선택된 카드가 있을 경우, customerKey 초기화
      setRandomkey(selectedBilling.customerKey);
    }
    else{
      setRandomkey(generateRandomString(20))
    }
  }, [selectedBilling]); // selectedBilling 변경 시 customerKey 업데이트
  console.log('After Function',randomKey);

  // 고객 키 설정
  const customerKey = randomKey;

  // ------ Toss SDK 초기화 함수 ------
  useEffect(() => {
    async function fetchPayment() {
      try {
        if (customerKey){
          const tossPayments = await loadTossPayments(clientKey);
          // 회원 결제
          // @docs https://docs.tosspayments.com/sdk/v2/js#tosspaymentspayment
          const payment = tossPayments.payment({
            customerKey
          });
          setPayment(payment);
        }
        // 비회원 결제
        // const payment = tossPayments.payment({ customerKey: ANONYMOUS });
        
      } catch (error) {
        console.error("Error fetching payment:", error);
      }
    }
    fetchPayment();
  }, [clientKey, customerKey]);

  // ------ '카드 등록하기' 버튼 누르면 결제창 띄우기 ------
  // @docs https://docs.tosspayments.com/sdk/v2/js#paymentrequestpayment
  async function requestBillingAuth() {
    // 결제를 요청하기 전에 orderId, amount를 서버에 저장하기
    // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
    try {
      // 결제를 요청하기 전에 orderId, amount를 서버에 저장하기
      await payment.requestBillingAuth({
        method: "CARD", // 자동결제(빌링)는 카드만 지원합니다
        successUrl: window.location.origin + "/payment/details", // 요청이 성공하면 리다이렉트되는 URL
        // /payment/details?customerKey={CUSTOMER_KEY}&authKey={AUTH_KEY}
        failUrl: window.location.origin + "/fail", // 요청이 실패하면 리다이렉트되는 URL
        // /fail?code={ERROR_CODE}&message={ERROR_MESSAGE}
        customerEmail: userInfo.email,
        customerName: userInfo.username,
      });
    } catch (error) {
      // 결제 창이 닫혔을 때 발생한 오류를 처리
      if (error.message && error.message.includes("취소되었습니다")) {
        alert("카드 등록이 취소되었습니다.");
      } else {
        alert("카드 등록 과정에서 오류가 발생했습니다. 나중에 다시 시도해주세요.");
      }
    }
  }

  // URL에서 customerKey와 authKey가 있는 경우 빌링 키 발급
  useEffect(() => {
    const token = getToken();
    const customerKeyFromURL = searchParams.get("customerKey");
    const authKeyFromURL = searchParams.get("authKey");
   
    // 이미 처리된 경우, 처리하지 않도록 조건을 추가
    const isAlreadyProcessed = sessionStorage.getItem("billingKeyIssued");

    if (customerKeyFromURL && authKeyFromURL && !isAlreadyProcessed) {
      sessionStorage.setItem("billingKeyIssued", "true");  // 처리 완료 표시
      setIsRedirected(true);
      setIsProcessing(true);  // "등록 중" 메시지 표시 시작
      async function issueBillingKey() {
        try {
          const requestData = {
            customerKey: customerKeyFromURL,
            authKey: authKeyFromURL,
          };
          
          // 빌링 키 발급 요청
          const response = await fetch(`${BACKEND_URL}/billing/issue-billing-key`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'x-auth-token': token,
            },
            body: JSON.stringify(requestData),
          });
          const json = await response.json();

          if (!response.ok) {
            throw { message: json.message, code: json.code };
          }
        } catch (err) {
          console.error('Fail to issue billing key', err);
          // navigate(`/fail?message=${err.message}&code=${err.code}`);
        }
      }
      issueBillingKey();
    }
      // URL 파라미터 처리 후, URL에서 파라미터 제거
      if (customerKeyFromURL && authKeyFromURL) {
        // URL에서 파라미터 제거
        // 3초 후 페이지 새로 고침
        // setTimeout(() => { window.location.reload();}, 3000);  // 3초 대기
        const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
        window.history.replaceState(null, '', newUrl);
        window.location.reload();
        // alert("카드 등록이 완료되었습니다.");
        console.log("등록되었습니다.")
        setIsProcessing(false);  // "등록 중" 메시지 표시 시작
      }
      
  }, [searchParams, token, isRedirected]);

  // 결제 요청 함수
  const handlePayment = async () => {
    try {
      if (!termsAgreed) {
        alert("개인정보 이용약관에 동의해주세요.");
        return;  // 약관에 동의하지 않으면 결제 진행 안함
      }
      
      if (!selectedBilling) {
        alert("결제에 사용할 카드 정보를 선택해주세요.");
        return;  // 카드 정보가 선택되지 않으면 결제를 진행하지 않음
      }
      const orderId = uuidv4();  // UUID로 고유한 주문 ID 생성
      const amount = price; // 결제 금액
      const orderName = planTitle;  // 주문 이름
      const customerEmail = userInfo.email;  // 고객 이메일
      const customerName = userInfo.username;  // 고객 이름
      const taxFreeAmount = 0;  // 면세 금액 (필요한 경우 수정)

      // 결제 요청 데이터
      const paymentData = {
        billingKey: selectedBilling.billingKey,  // 선택된 카드의 billingKey
        customerKey: selectedBilling.customerKey,  // 선택된 카드의 customerKey
        amount,
        orderId,
        orderName,
        customerEmail,
        customerName,
        taxFreeAmount,
      };

      // 결제 요청을 백엔드로 보내기
      const response = await fetch(`${BACKEND_URL}/billing/pay`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'x-auth-token': token,  // 인증 토큰
        },
        body: JSON.stringify(paymentData),
      });

      const data = await response.json();
      
      if (response.ok) {
        console.log('결제 요청 성공:', data);
        alert("결제가 성공적으로 처리되었습니다.");
        // 결제 완료 후 리다이렉트나 추가 작업을 진행할 수 있습니다.
      } else {
        console.error('결제 요청 실패:', data);
        alert("결제 요청에 실패했습니다.");
      }
    } catch (error) {
      console.error("결제 요청 중 오류 발생:", error);
      alert("서버 오류가 발생했습니다. 나중에 다시 시도해주세요.");
    }
  };

  // 취소 버튼 클릭 시 처리 함수
  const handleCancel = () => {
    navigate('/payment');  // '/payment' 페이지로 리다이렉트
  };

  // 현재 날짜와 1달 뒤 날짜 계산
  const today = new Date();
  const nextMonth = new Date();
  nextMonth.setMonth(today.getMonth() + 1);

  // 날짜 형식 변환 함수 (YYYY-MM-DD 형식)
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year} - ${month} - ${day}`;
  };


  return (
    <div className="payment-page">
      {/* 등록 중 메시지 */}
      {isProcessing && (
      <div className="overlay">
        <div className="message">등록 중...</div>
      </div>
      )}
      <h2>구독 결제</h2>
      <div className="payment-container">
        {/* 플랜 요약 섹션 */}
        <div className="plan-summary-box">
          <div className="plan-summary">
            <div className="plan-header">
              <span className="plan-title professional-plan">{subscriptionInfo.planTitle || '선택된 플랜'}</span>
            </div>
            <div className="plan-details">
              <div className="left-section">
                <p className="price">{subscriptionInfo.price ? `${subscriptionInfo.price.toLocaleString()}원 / 월` : '가격 정보 없음'}</p>
                <div className="token-slider">
                  <div className="token-amount">{subscriptionInfo.tokens ? `${subscriptionInfo.tokens.toLocaleString()} tokens` : '토큰 정보 없음'}</div>
                  <input type="range" min="0" max="1000000" value={tokens || 0} readOnly />
                  <div className="slider-range">
                    <span>0 tokens</span>
                    <span>1,000,000 tokens</span>
                  </div>
                </div>
              </div>
              {/* features 리스트 출력 */}
              <ul className="features">
                {subscriptionInfo.features && subscriptionInfo.features.length > 0 ? (
                  subscriptionInfo.features.map((feature, index) => (
                    <li key={index}>
                      <CheckCircle size={16} />
                      {feature}
                    </li>
                  ))
                ) : (
                  <li>플랜 설명이 없습니다.</li>
                )}
              </ul>
            </div>
          </div>
        </div>

        {/* 결제 정보 및 수단 섹션 */}
        <div className="payment-info-box">
          <div className="payment-details">
            <h3>결제 정보</h3>
            <div className="payment-details-list">
              <div className="payment-detail-item">
                <span className="detail-label">결제 금액:</span>
                <span className="detail-value">{subscriptionInfo.price ? `${subscriptionInfo.price.toLocaleString()}원` : '정보 없음'}</span>
              </div>
              <div className="payment-detail-item">
                <span className="detail-label">결제일:</span>
                <span className="detail-value">{formatDate(today)}</span>
              </div>
              <div className="payment-detail-item">
                <span className="detail-label">다음 결제일:</span>
                <span className="detail-value">{formatDate(nextMonth)}</span>
              </div>
            </div>
          </div>
          {/* 빌링 정보 섹션 */}
          {billingInfo && (
            <div className="billing-info-box">
              <h3>등록된 카드</h3>
              <div className="billing-options">
                {billingInfo.map((info, index) => (
                  <div key={index} className="billing-option">
                    <label>
                      <input
                        type="radio"
                        name="billingOption"
                        value={info.customerKey} // Use customerKey as the value
                        onChange={() => setSelectedBilling(info)} // Store the selected billing info
                        checked={selectedBilling?.customerKey === info.customerKey} // Check if this option is selected
                      />
                      <div className="billing-card">
                        {/* 카드 이미지 */}
                        <img
                          src={credit_card} // Correctly set the src to the imported image
                          alt={`Card ${index + 1}`}
                          className="card-image"
                        />
                        {/* 카드 번호 마스킹 처리 */}
                        <div className="masked-card-number">
                          {info.cardNumber} {/* 마스킹된 카드 번호 */}
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="payment-methods">
            <h3>카드 등록</h3>
            <div className="methods-buttons">
              <button onClick={requestBillingAuth}>추가하기</button>
            </div>
          </div>

          <div className="terms">
            <label>
              <input type="checkbox" 
              required
              checked={termsAgreed}
              onChange={(e) => setTermsAgreed(e.target.checked)}/>
              [필수] 결제 서비스 이용 약관, 개인정보 처리 동의
            </label>
            <label>
              <input type="checkbox" 
              onChange={(e) => console.log(e.target.checked)} // 선택적인 동의 항목
             />
              [선택] 홍보성 정보 수신 동의
            </label>
          </div>
        </div>
      </div>

      {/* 하단 버튼 섹션 */}
      <div className="payment-action-buttons">
        <button onClick={handleCancel} className="cancel-btn">취소</button>
        <button onClick={handlePayment} className="confirm-btn">결제하기</button>
      </div>
    </div>
  );
};

export default PaymentPage;