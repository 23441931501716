import React from 'react';
import { IoClose, IoCheckmark } from 'react-icons/io5';
import guide1 from '../../../assets/images/photosgenerator/guide1.png';
import guide2 from '../../../assets/images/photosgenerator/guide2.png';
import guide3 from '../../../assets/images/photosgenerator/guide3.png';
import guide4 from '../../../assets/images/photosgenerator/guide4.png';
import guide5 from '../../../assets/images/photosgenerator/guide5.png';

const ImageGuide = () => {
  return (
    <div className="image-guide">
      <h2 className="image-guide__title">사진 규격 가이드</h2>
      <div className="image-guide__content">
        <div className="image-guide__section">
          <div className="image-guide__header">
            <div className="image-guide__label invalid">
              <IoClose className="image-guide__icon" />
              부적절해요
            </div>
            <p className="image-guide__description">
              얼굴이 가려지거나 잘린 사진, 얼굴이 너무 멀리 있는 사진, 정면이 아닌 사진 등
            </p>
          </div>
          <div className="image-guide__images">
            <img src={guide1} alt="부적절한 예시 1" />
            <img src={guide2} alt="부적절한 예시 2" />
            <img src={guide3} alt="부적절한 예시 3" />
          </div>
        </div>

        <div className="image-guide__section">
          <div className="image-guide__header">
            <div className="image-guide__label valid">
              <IoCheckmark className="image-guide__icon" />
              좋아요!
            </div>
            <p className="image-guide__description">
              증명사진과 비슷한 규격의 사진, 정면 사진
            </p>
          </div>
          <div className="image-guide__images">
            <img src={guide4} alt="적절한 예시 1" />
            <img src={guide5} alt="적절한 예시 2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGuide;