import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import SpinningLogo from '../SpinningLogo';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const PreparationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  background: #fff;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  position: relative;
`;

const LoadingScreen = styled.div`
  width: 100%;
  max-width: 600px;
  position: relative;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 60px;
  height: 60px;
  z-index: 2;
`;

const InfoCard = styled.div`
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 12px;
  width: 100%;
  position: relative;
`;

const Title = styled.h2`
  font-size: 1.8rem;
  color: #343a40;
  margin-bottom: 1rem;
  font-weight: 600;
`;

const InterviewInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 1.5rem 0;
  background: white;
  padding: 1rem;
  border-radius: 8px;
`;

const InfoItem = styled.div`
  text-align: center;
  
  h4 {
    font-size: 0.9rem;
    color: #868e96;
    margin-bottom: 0.25rem;
  }
  
  p {
    font-size: 1rem;
    color: #495057;
    font-weight: 500;
  }
`;

const StageList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.75rem;
  margin-top: 1.5rem;
`;

const StageItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  background: ${props => props.active ? '#e7f5ff' : 'white'};
  border-radius: 8px;
  transition: all 0.2s ease;

  .icon {
    font-size: 1.25rem;
    color: ${props => props.active ? '#228be6' : '#adb5bd'};
  }

  .stage-title {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${props => props.active ? '#228be6' : '#495057'};
    text-align: center;
  }

  .status {
    font-size: 0.75rem;
    color: ${props => props.active ? '#228be6' : '#adb5bd'};
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 3px;
  background: #e9ecef;
  border-radius: 4px;
  margin: 1rem 0;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${props => props.progress}%;
    background: #228be6;
    transition: width 0.3s ease;
  }
`;

const InterviewPreparation = ({ isLoading, userName, jobField, interviewType }) => {
  const [progress, setProgress] = useState(0);
  const [currentStage, setCurrentStage] = useState(0);
  
  const stages = [
    {
      icon: '📋',
      title: '자료 분석 중',
      desc: '자기소개서와 이력서를 분석하고 있습니다',
      time: 5
    },
    {
      icon: '🤖',
      title: 'AI 면접관 준비',
      desc: '맞춤형 면접 질문을 생성하고 있습니다',
      time: 10
    },
    {
      icon: '🎯',
      title: '면접 환경 설정',
      desc: '최적의 면접 환경을 구성하고 있습니다',
      time: 8
    },
    {
      icon: '✨',
      title: '최종 점검',
      desc: '모든 준비가 완료되었습니다',
      time: 2
    }
  ];

  const totalTime = stages.reduce((acc, stage) => acc + stage.time, 0);

  useEffect(() => {
    if (isLoading) {
      let currentTime = 0;
      
      // InterviewQuestions.jsx의 startInterview 함수와 시간 동기화
      // startLine: 231, endLine: 271
      const interval = setInterval(() => {
        currentTime += 0.1;
        const currentProgress = (currentTime / totalTime) * 100;
        
        if (currentProgress >= 100) {
          clearInterval(interval);
          setProgress(100);
          return;
        }

        setProgress(currentProgress);

        // 현재 진행 단계 계산
        let timeSum = 0;
        for (let i = 0; i < stages.length; i++) {
          timeSum += stages[i].time;
          if (currentTime <= timeSum) {
            setCurrentStage(i);
            break;
          }
        }
      }, 100);

      return () => clearInterval(interval);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isLoading) {
      const speech = new SpeechSynthesisUtterance();
      speech.text = `${userName}님, 면접 준비를 시작하겠습니다.`;
      speech.lang = 'ko-KR';
      window.speechSynthesis.speak(speech);
    }
  }, [isLoading, userName]);

  return (
    <PreparationContainer>
      <ProgressBar progress={progress} />
      <SpinnerContainer>
        <SpinningLogo size="small" />
      </SpinnerContainer>
      
      <LoadingScreen>
        <InfoCard>
          <Title>면접 준비중</Title>
          <InterviewInfo>
            <InfoItem>
              <h4>면접자</h4>
              <p>{userName}</p>
            </InfoItem>
            <InfoItem>
              <h4>직무</h4>
              <p>{jobField}</p>
            </InfoItem>
            <InfoItem>
              <h4>면접 유형</h4>
              <p>{interviewType}</p>
            </InfoItem>
          </InterviewInfo>

          <StageList>
            {stages.map((stage, index) => (
              <StageItem key={index} active={currentStage === index}>
                <div className="icon">{stage.icon}</div>
                <div className="stage-title">{stage.title}</div>
                <div className="status">
                  {currentStage === index ? '진행중...' : 
                   currentStage > index ? '완료' : '대기중'}
                </div>
              </StageItem>
            ))}
          </StageList>
        </InfoCard>
      </LoadingScreen>
    </PreparationContainer>
  );
};

export default InterviewPreparation; 