import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import headerLogo from '../assets/images/logos/headerlogo.svg';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
          <h4>고객센터</h4>
          <p>운영시간: 10:00 ~ 19:00</p>
          <p>점심시간: 12:30 ~ 13:30</p>
          <p>주말 및 공휴일 휴무</p>
          <p>이메일: <a href="mailto:jobmaker.ai@gmail.com">jobmaker.ai@gmail.com</a></p>
          <p>전화 문의: 010-2075-5278</p>
          <p><a href="/">1:1 채팅 문의</a></p>
        </div>
        <div className="footer-right">
          <h4>제휴 및 채용문의</h4>
          <p>일반 채용 문의: <a href="mailto:111111@111111.com">jobmaker.ai@gmail.com</a></p>
          <p>번역가 채용 문의: <a href="mailto:111111@111111.com">jobmaker.ai@gmail.com</a></p>
          <p>기업 번역 문의: <a href="mailto:111111@111111.com">jobmaker.ai@gmail.com</a></p>
          <p>사업 제휴 문의: <a href="mailto:111111@111111.com">jobmaker.ai@gmail.com</a></p>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-logo">
          <img src={headerLogo} alt="잡메이커 로고" style={{ width: '150px', height: 'auto' }} />
          <p>주식회사 잡메이커 | 부산광역시 남구 부경대 용당캠퍼스 3층</p>
          <p>대표자: 신희성 | 사업자 등록번호: 111111</p>
          <p>통신판매번호: 111111</p>
        </div>
        <div className="footer-social-icons">
          <a href="https://instagram.com" aria-label="Instagram">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://youtube.com" aria-label="YouTube">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </div>
      </div>

      <div className="footer-policy">
        <a href="/terms">이용약관</a> | <a href="/privacy">개인정보 처리방침</a> 
      </div>
      <p className="copyright">© 2024 잡메이커 Inc. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
