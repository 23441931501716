import React from 'react';
import './PrivacyPage.css';

const PrivacyPage = () => {
  return (
    <div className="privacy-page">
      <div className="privacy-container">
        <h1>개인정보 처리방침</h1>
        <div className="privacy-content">
          <section>
            <h2>1. 개인정보의 수집 및 이용 목적</h2>
            <p>회사는 다음과 같은 목적으로 개인정보를 수집 및 이용합니다:</p>
            <ul>
              <li>서비스 제공 및 계정 관리</li>
              <li>고객 지원 및 문의 응대</li>
              <li>서비스 개선 및 신규 서비스 개발</li>
              <li>마케팅 및 광고 활용</li>
            </ul>
          </section>

          <section>
            <h2>2. 수집하는 개인정보 항목</h2>
            <p>회사가 수집하는 개인정보 항목은 다음과 같습니다:</p>
            <ul>
              <li>필수항목: 이름, 이메일 주소, 비밀번호</li>
              <li>선택항목: 전화번호, 프로필 이미지</li>
              <li>자동수집항목: 서비스 이용기록, IP 주소, 쿠키, 방문일시</li>
            </ul>
          </section>

          <section>
            <h2>3. 개인정보의 보유 및 이용기간</h2>
            <p>회사는 회원탈퇴 시 또는 수집・이용목적 달성 시까지 개인정보를 보유합니다.</p>
            <ul>
              <li>계약 또는 청약철회 등에 관한 기록: 5년</li>
              <li>대금결제 및 재화 등의 공급에 관한 기록: 5년</li>
              <li>소비자의 불만 또는 분쟁처리에 관한 기록: 3년</li>
              <li>부정이용 등에 관한 기록: 1년</li>
            </ul>
          </section>

          <section>
            <h2>4. 개인정보의 파기절차 및 방법</h2>
            <p>회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</p>
            <ul>
              <li>전자적 파일 형태인 경우 복구 및 재생되지 않도록 안전하게 삭제</li>
              <li>그 외의 기록물, 인쇄물, 서면 등은 분쇄하거나 소각</li>
            </ul>
          </section>

          <section>
            <h2>5. 개인정보보호 책임자</h2>
            <p>회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보보호 책임자를 지정하고 있습니다.</p>
            <ul>
              <li>개인정보보호 책임자</li>
              <li>- 이름: 홍길동</li>
              <li>- 직책: CPO</li>
              <li>- 연락처: privacy@jobmaker.ai</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage; 