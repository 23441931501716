// pages/tools/interview/AIInterviewerPage.jsx
// ai 면접 페이지
import React, { useState } from 'react';
import styled from 'styled-components';
import InterviewQuestions from '../../../components/interview/InterviewQuestions';

const AIInterviewerPage = () => {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({
    name: '',
    jobField: '',
    interviewType: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <Container>
      
      {step === 1 ? (
        <UserDataForm>
          <InputGroup>
            <Label>이름</Label>
            <Input
              type="text"
              name="name"
              value={userData.name}
              onChange={handleInputChange}
              placeholder="이름을 입력하세요"
            />
          </InputGroup>
          
          <InputGroup>
            <Label>관심 직무</Label>
            <Select
              name="jobField"
              value={userData.jobField}
              onChange={handleInputChange}
            >
              <option value="">직무를 선택하세요</option>
              <option value="frontend">프론트엔드 개발자</option>
              <option value="backend">백엔드 개발자</option>
              <option value="fullstack">풀스택 개발자</option>
              <option value="design">UI/UX 디자이너</option>
            </Select>
          </InputGroup>

          <InputGroup>
            <Label>면접 유형</Label>
            <Select
              name="interviewType"
              value={userData.interviewType}
              onChange={handleInputChange}
            >
              <option value="">면접 유형을 선택하세요</option>
              <option value="technical">기술 면접</option>
              <option value="behavioral">인성 면접</option>
              <option value="culture">컬쳐핏 면접</option>
            </Select>
          </InputGroup>

          <Button 
            onClick={() => setStep(2)}
            disabled={!userData.name || !userData.jobField || !userData.interviewType}
          >
            면접 시작하기
          </Button>
        </UserDataForm>
      ) : (
        <InterviewQuestions 
          jobField={userData.jobField}
          interviewType={userData.interviewType}
          userName={userData.name}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  color: #2E74FF;
  text-align: center;
  margin-bottom: 2rem;
`;

const UserDataForm = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const InputGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: #2E74FF;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: #2E74FF;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  background: #2E74FF;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  
  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  &:hover:not(:disabled) {
    background: #1b5cd9;
  }
`;

export default AIInterviewerPage;
