import React from 'react';
import './TermsPage.css';

const TermsPage = () => {
  return (
    <div className="terms-page">
      <div className="terms-container">
        <h1>이용약관</h1>
        <div className="terms-content">
          <section>
            <h2>제1장 총칙</h2>
            <article>
              <h3>제1조 (목적)</h3>
              <p>본 약관은 주식회사 잡메이커(이하 "회사")가 제공하는 서비스의 이용조건 및 절차, 회사와 회원 간의 권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.</p>
            </article>
            
            <article>
              <h3>제2조 (용어의 정의)</h3>
              <p>본 약관에서 사용하는 용어의 정의는 다음과 같습니다:</p>
              <ul>
                <li>"서비스"란 회사가 제공하는 모든 서비스를 의미합니다.</li>
                <li>"회원"이란 회사와 서비스 이용계약을 체결한 개인을 의미합니다.</li>
                <li>"아이디(ID)"란 회원의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가 승인한 이메일 주소를 의미합니다.</li>
              </ul>
            </article>
          </section>

          <section>
            <h2>제2장 서비스 이용</h2>
            <article>
              <h3>제3조 (서비스의 제공)</h3>
              <p>회사는 다음과 같은 서비스를 제공합니다:</p>
              <ul>
                <li>AI 기반 자기소개서 생성 서비스</li>
                <li>AI 면접 준비 서비스</li>
                <li>이력서 작성 및 평가 서비스</li>
                <li>취업 정보 제공 서비스</li>
                <li>커뮤니티 서비스</li>
              </ul>
            </article>

            <article>
              <h3>제4조 (서비스 이용 시간)</h3>
              <p>서비스는 연중무휴, 1일 24시간 제공됩니다. 다만, 회사는 서비스의 종류나 성질에 따라 제공 시간을 별도로 정할 수 있습니다.</p>
            </article>
          </section>

          <section>
            <h2>제3장 의무 및 책임</h2>
            <article>
              <h3>제5조 (회사의 의무)</h3>
              <p>회사는 안정적인 서비스 제공을 위해 최선을 다하며, 서비스 제공과 관련하여 취득한 회원의 개인정보를 본인의 승낙 없이 제3자에게 제공하지 않습니다.</p>
            </article>

            <article>
              <h3>제6조 (회원의 의무)</h3>
              <p>회원은 다음 행위를 해서는 안 됩니다:</p>
              <ul>
                <li>타인의 정보도용</li>
                <li>회사가 제공하는 서비스의 부정한 사용</li>
                <li>서비스의 운영을 방해하는 행위</li>
                <li>타인의 명예를 손상시키거나 불이익을 주는 행위</li>
              </ul>
            </article>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsPage; 