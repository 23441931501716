import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import VoiceRecorder from './VoiceRecorder';
import { getToken } from '../../utils/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SpinningLogo from '../SpinningLogo';
import InterviewPreparation from './InterviewPreparation';
import InterviewTypeSelection from './InterviewTypeSelection';

const Container = styled.div`
  max-width: 1000px;
  margin: 40px auto;
  padding: 40px 20px;
  font-family: 'Noto Sans KR', sans-serif;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
`;

const Title = styled.h1`
  font-size: 24pt;
  margin-bottom: 20px;
  color: #333;
`;

const DocumentSelection = styled.div`
  margin-bottom: 2rem;
`;

const SelectionSection = styled.div`
  margin-bottom: 2rem;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
`;

const SubTitle = styled.h3`
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 8px;
  
  &:before {
    content: '📝';
  }
`;

const DocumentList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.2rem;
`;

const DocumentItem = styled.div`
  padding: 1.2rem;
  border: 2px solid ${props => props.isselected ? '#007bff' : '#e9ecef'};
  border-radius: 12px;
  cursor: pointer;
  background-color: ${props => props.isselected ? '#f8f9fa' : '#fff'};
  transition: all 0.2s ease-in-out;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

const DocumentTitle = styled.div`
  font-weight: 600;
  margin-bottom: 0.8rem;
  color: #333;
`;

const DocumentDate = styled.div`
  font-size: 0.9rem;
  color: #6c757d;
`;

const StartButton = styled.button`
  width: 100%;
  padding: 1.2rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-top: 2rem;
  
  &:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
  }
  
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const InterviewSection = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const InterviewQuestions = ({ jobField, userName }) => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userAnswer, setUserAnswer] = useState('');
  const [isInterviewStarted, setIsInterviewStarted] = useState(false);
  const [coverLetters, setCoverLetters] = useState([]);
  const [selectedCoverLetter, setSelectedCoverLetter] = useState(null);
  const [resumes, setResumes] = useState([]);
  const [selectedResume, setSelectedResume] = useState(null);
  const [error, setError] = useState(null);
  const [interviewId, setInterviewId] = useState(null);
  const [recordedAudioUrl, setRecordedAudioUrl] = useState(null);
  const [isInterviewCompleted, setIsInterviewCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedInterviewType, setSelectedInterviewType] = useState(null);
  const navigate = useNavigate();

  // 자기소개서 목록 가져오기
  const fetchCoverLetters = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/tool1/coverletters`, {
        headers: {
          'x-auth-token': token
        }
      });
      
      const completedCoverLetters = response.data.filter(
        coverLetter => coverLetter.contentStatus === 'published'
      );
      
      setCoverLetters(completedCoverLetters);
    } catch (error) {
      console.error('자기소개서 목록 로드 오류:', error);
      setError('자기소개서 목록을 불러오는데 실패했습니다.');
    }
  };

  // 이력서 목록 가져오기
  const fetchResumes = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/resume/list`, {
        headers: {
          'x-auth-token': token
        }
      });
      
      if (response.data) {
        // 완료된 이력서만 필터링
        const completedResumes = response.data.filter(
          resume => resume.status === 'published'
        );
        setResumes(completedResumes);
      }
    } catch (error) {
      console.error('이력서 목록 로드 오류:', error);
      setError('이력서 목록을 불러오는데 실패했습니다.');
    }
  };

  // 자기소개서와 이력서 목록 로드
  useEffect(() => {
    fetchCoverLetters();
    fetchResumes();
  }, []);

  // 자기소개서 선택 핸들러
  const handleCoverLetterSelect = async (coverLetterId) => {
    try {
      const token = getToken();
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/tool1/${coverLetterId}`, {
        headers: {
          'x-auth-token': token
        }
      });

      const coverLetter = response.data;
      if (!coverLetter.content || coverLetter.content.length === 0) {
        alert('선택한 자기소개서의 내용이 없습니다.');
        return;
      }

      setSelectedCoverLetter(coverLetter);
    } catch (error) {
      console.error('자기소개서 불러오기 실패:', error);
      alert('자기소개서를 불러오는데 실패했습니다.');
    }
  };

  // 이력서 선택 핸들러
  const handleResumeSelect = async (resumeId) => {
    try {
      const token = getToken();
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/resume/${resumeId}`, {
        headers: {
          'x-auth-token': token
        }
      });

      if (response.data) {
        setSelectedResume(response.data);
      } else {
        alert('이력서를 불러오는데 실패했습니다.');
      }
    } catch (error) {
      console.error('이력서 불러오기 실패:', error);
      alert('이력서를 불러오는데 실패했습니다.');
    }
  };

  // 면접 시작
  const startInterview = async () => {
    if (!selectedInterviewType) {
      alert('면접 유형을 선택해주세요.');
      return;
    }

    setIsLoading(true);
    setIsInterviewStarted(true);  // 여기서 먼저 시작 상태로 변경

    try {
      if (!selectedCoverLetter || !selectedResume) {
        alert('면접을 시작하기 전에 자기소개서와 이력서를 모두 선택해주세요.');
        return;
      }

      const token = getToken();
      if (selectedInterviewType === 'text') {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/interview/start`,
          {
            jobField,
            interviewType: 'text',
            resumeData: selectedResume.resumeData,
            coverLetterContent: selectedCoverLetter.content
          },
          {
            headers: {
              'x-auth-token': token
            }
          }
        );

        if (response.data.success) {
          // 3초 후에 면접 시작
          setTimeout(() => {
            setQuestions(response.data.data.questions);
            setInterviewId(response.data.data.interviewId);
            setIsLoading(false);
          }, 3000);
        } else {
          alert('면접 시작에 실패했습니다.');
          setIsLoading(false);
        }
      } else {
        // 음성 면접 페이지로 이동
        navigate('/interview/dynamic', {
          state: {
            jobField,
            interviewType: 'voice',
            resumeId: selectedResume._id,
            coverLetterId: selectedCoverLetter._id
          }
        });
      }
    } catch (error) {
      console.error('면접 시작 오류:', error);
      alert('면접을 시작하는 중 오류가 발생했습니다.');
      setIsLoading(false);
    }
  };

  // 답변 제출 핸들러
  const handleSubmitAnswer = async (audioUrl) => {
    if (!audioUrl) {
      alert('음성 답변이 필요합니다.');
      return;
    }

    setIsSubmitting(true);
    try {
      const token = getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/interview/submit`,
        {
          interviewId,
          questionIndex: currentQuestionIndex,
          audioUrl: audioUrl
        },
        {
          headers: { 'x-auth-token': token }
        }
      );

      if (response.data.success) {
        setFeedback(response.data.data.feedback);
        
        if (response.data.data.isCompleted) {
          setIsInterviewCompleted(true);
          navigate(`/interview/result/${interviewId}`);
        } else {
          setTimeout(() => {
            setCurrentQuestionIndex(prev => prev + 1);
            setFeedback(null);
            setRecordedAudioUrl(null);
          }, 3000);
        }
      }
    } catch (error) {
      console.error('답변 제출 오류:', error);
      alert('답변을 제출하는 중 오류가 발생했습니다.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVoiceAnswer = async (audioBlob) => {
    try {
      // 음성 파일 S3 업로드
      const formData = new FormData();
      formData.append('audio', audioBlob);
      
      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/upload/audio`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': getToken()
          }
        }
      );

      // 음성 답변 제출
      await handleSubmitAnswer(uploadResponse.data.audioUrl);
    } catch (error) {
      console.error('음성 답변 처리 오류:', error);
      alert('음성 답변 처리 중 오류가 발생했습니다.');
    }
  };

  // 텍스트 답변 제출 핸들러 추가
  const handleTextAnswer = async () => {
    if (!userAnswer.trim()) {
      alert('답변을 입력해주세요.');
      return;
    }

    setIsSubmitting(true);
    try {
      const token = getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/interview/answer`,
        {
          interviewId,
          questionIndex: currentQuestionIndex,
          answer: userAnswer,
          type: 'text'
        },
        {
          headers: {
            'x-auth-token': token
          }
        }
      );

      if (response.data.success) {
        setFeedback(response.data.data.feedback);
        
        // 다음 질문으로 이동 또는 면접 종료
        if (currentQuestionIndex < questions.length - 1) {
          setTimeout(() => {
            setCurrentQuestionIndex(prev => prev + 1);
            setUserAnswer('');
            setFeedback(null);
          }, 3000);
        } else {
          setIsInterviewCompleted(true);
          navigate(`/interview/result/${interviewId}`);
        }
      }
    } catch (error) {
      console.error('답변 제출 오류:', error);
      alert('답변을 제출하는 중 오류가 발생했습니다.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // DynamicInterview에서 가져온 speakQuestion 함수
  const speakQuestion = async (questionText) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/interview/speak`,
        { text: questionText },
        { 
          headers: { 'x-auth-token': getToken() },
          responseType: 'arraybuffer'
        }
      );

      await playQuestionAudio(response.data);
    } catch (error) {
      console.error('질문 음성 변환 오류:', error);
      const utterance = new SpeechSynthesisUtterance(questionText);
      utterance.lang = 'ko-KR';
      window.speechSynthesis.speak(utterance);
    }
  };

  const playQuestionAudio = async (audioData) => {
    const audioBlob = new Blob([audioData], { type: 'audio/mpeg' });
    const audioUrl = URL.createObjectURL(audioBlob);
    const audio = new Audio(audioUrl);
    
    await audio.play();
    
    return new Promise((resolve) => {
      audio.onended = () => {
        URL.revokeObjectURL(audioUrl);
        resolve();
      };
    });
  };

  return (
    <Container>
      {!isInterviewStarted ? (
        // 초기 선택 화면
        <>
          <DocumentSelection>
            <Title>면접 준비</Title>
            <SelectionSection>
              <SubTitle>자기소개서 선택</SubTitle>
              <DocumentList>
                {coverLetters.map((cl) => (
                  <DocumentItem
                    key={cl._id}
                    isselected={selectedCoverLetter?._id === cl._id}
                    onClick={() => handleCoverLetterSelect(cl._id)}
                  >
                    <DocumentTitle>{cl.title || '제목 없음'}</DocumentTitle>
                    <DocumentDate>
                      {new Date(cl.createdAt).toLocaleDateString()}
                    </DocumentDate>
                  </DocumentItem>
                ))}
              </DocumentList>
            </SelectionSection>

            <SelectionSection>
              <SubTitle>이력서 선택</SubTitle>
              <DocumentList>
                {resumes.map((resume) => (
                  <DocumentItem
                    key={resume._id}
                    isselected={selectedResume?._id === resume._id}
                    onClick={() => handleResumeSelect(resume._id)}
                  >
                    <DocumentTitle>{resume.title || '제목 없음'}</DocumentTitle>
                    <DocumentDate>
                      {new Date(resume.createdAt).toLocaleDateString()}
                    </DocumentDate>
                  </DocumentItem>
                ))}
              </DocumentList>
            </SelectionSection>
          </DocumentSelection>
          
          <InterviewTypeSelection 
            onSelect={setSelectedInterviewType} 
            selectedType={selectedInterviewType}
          />
          
          <StartButton
            onClick={startInterview}
            disabled={!selectedCoverLetter || !selectedResume || !selectedInterviewType}
          >
            {selectedInterviewType === 'voice' ? '음성 면접 시작하기' : '텍스트 면접 시작하기'}
          </StartButton>
        </>
      ) : isLoading ? (
        // 준비 화면
        <InterviewPreparation 
          isLoading={isLoading}
          userName={userName}
          jobField={jobField}
          interviewType={selectedInterviewType}
        />
      ) : questions.length > 0 ? (
        // 면접 질문 화면
        <InterviewSection>
          <QuestionSection>
            <QuestionText>
              {questions[currentQuestionIndex]?.question}
            </QuestionText>
          </QuestionSection>

          <AnswerSection>
            <AnswerInput
              value={userAnswer}
              onChange={(e) => setUserAnswer(e.target.value)}
              placeholder="답변을 입력하세요..."
              disabled={isSubmitting}
            />
            <ActionButton 
              onClick={handleTextAnswer}
              disabled={isSubmitting || !userAnswer.trim()}
            >
              {isSubmitting ? '제출 중...' : '답변 제출'}
            </ActionButton>
          </AnswerSection>

          {feedback && (
            <FeedbackSection>
              <FeedbackText>{feedback}</FeedbackText>
            </FeedbackSection>
          )}
        </InterviewSection>
      ) : null}
    </Container>
  );
};

const QuestionSection = styled.div`
  margin-bottom: 2rem;
`;

const QuestionText = styled.p`
  font-size: 1.2rem;
  color: #333;
`;

const AnswerSection = styled.div`
  margin-bottom: 2rem;
`;

const AnswerInput = styled.textarea`
  width: 100%;
  min-height: 150px;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 1rem;
  resize: vertical;
`;

const ActionButton = styled.button`
  padding: 0.8rem 1.5rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  &:hover {
    background-color: #218838;
  }
`;

const FeedbackSection = styled.div`
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
`;

const FeedbackText = styled.p`
  color: #333;
  line-height: 1.5;
`;

export default InterviewQuestions; 