import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';  // 기본 import를 명명된 import로 변경
import { 
  FaHome, 
  FaSearch, 
  FaComments, 
  FaUser, 
  FaBell, 
  FaSignOutAlt, 
  FaBars, 
  FaChevronDown, 
  FaChevronUp, 
  FaCoins, 
  FaSignInAlt, 
  FaUserCog,
  FaTools,
  FaPencilAlt,
  FaCheckSquare,
  FaCamera,
  FaQuestionCircle,
  FaProjectDiagram,
  FaHandshake,
  FaUserTie,
  FaFileAlt,
  FaRegBell,
  FaTimes
} from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Sidebar.css';
import AlertPopup from './AlertPopup';
import { getToken, removeToken } from '../utils/auth';
import namelogo from '../assets/images/logos/namelogo.svg';
import starLogo from '../assets/images/logos/starlogo.png';

// 환경 변수에서 백엔드 URL 가져오기
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// 토큰을 디코딩하여 만료 시간 체크하는 함수
const isTokenExpired = (token) => {
  if (!token) return true;
  
  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000; // 현재 시간을 초 단위로
  return decoded.exp < currentTime; // 만약 토큰의 만료 시간이 현재 시간보다 이전이면 만료된 토큰
};

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isToolsOpen, setIsToolsOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [tokens, setTokens] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isToolsDrawerOpen, setIsToolsDrawerOpen] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const token = getToken();
    if (token && !isTokenExpired(token)) {
      setIsLoggedIn(true);
      console.log(isLoggedIn);
      const fetchUserData = async () => {
        try {
          const response = await fetch(`${BACKEND_URL}/user`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': token,
            },
          });

          if (response.ok) {
            const data = await response.json();
            setUsername(data.username);
            setTokens(data.tokens);
          } else {
            console.error('Failed to fetch user data');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
      fetchUserData();
    }
    else{
      setIsLoggedIn(false);
      removeToken();
    }
  }, [location]);

  const toggleToolsMenu = () => {
    setIsToolsOpen(!isToolsOpen);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/auth/logout`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': getToken(),
        },
      });

      if (response.ok) {
        removeToken();
        alert('로그아웃되었습니다.');
        navigate('/');
      } else {
        alert('로그아웃 실패. 다시 시도해주세요.');
      }
    } catch (error) {
      console.error('Error during logout:', error);
      alert('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  const handleTokenClick = () => {
    navigate('/payment');
  };

  // 현재 경로에 따라 아이콘 색상을 결정하는 함수 수정
  const getIconColor = (path) => {
    if (path === '/tools') {
      return location.pathname.startsWith('/tools/') ? '#2E74FF' : 'white';
    }
    if (path === '/main') {
      return location.pathname === '/main' ? '#2E74FF' : 'white';
    }
    return location.pathname.startsWith(path) ? '#2E74FF' : 'white';
  };

  // 취업도구 메뉴 클릭 핸들러 수정
  const handleToolsClick = () => {
    setIsToolsOpen(!isToolsOpen);
  };

  // 현재 경로가 해당 메뉴의 경로와 일치하는지 확인하는 함수
  const isActive = (path) => {
    if (path === '/tools') {
      return false;
    }
    if (path === '/main') {
      return location.pathname === '/main';
    }
    return location.pathname.startsWith(path);
  };

  // 모바일 메뉴 아이템 - 간소화된 버전
  const mobileMenuItems = [
    { icon: <FaHome />, title: "홈", path: "/main" },
    { 
      icon: <FaTools />, 
      title: "도구", 
      onClick: () => setIsToolsDrawerOpen(!isToolsDrawerOpen),
      subMenu: [
        { icon: <FaPencilAlt />, title: "자소서 작성", path: "/tools/resume-generator/setinfo" },
        { icon: <FaCamera />, title: "증명사진 생성", path: "/tools/photo-generator" },
        { icon: <FaCheckSquare />, title: "자소서 테스트", path: "/tools/resume-evaluation" },
        { icon: <FaQuestionCircle />, title: "예상 면접 질문", path: "/tools/questions-setInfo" },
        { icon: <FaProjectDiagram />, title: "필요한 스펙 분석", path: "/tools/tech-tree-generator" },
        { icon: <FaHandshake />, title: "잡 매칭", path: "/tools/job-matching" },
        { icon: <FaUserTie />, title: "AI 면접", path: "/tools/ai-interviewer" }
      ]
    },
    { icon: <FaSearch />, title: "채용", path: "/recruit/info" },
    { icon: <FaComments />, title: "커뮤니티", path: "/community" },
    { icon: <FaUser />, title: "프로필", onClick: () => setIsProfileOpen(!isProfileOpen) }
  ];

  return (
    <>
      <div className={`sidebar ${isOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        {!isMobile && (
          // 기존 PC 버전 사이드바 내용
          <>
            <div className="sidebar-logo">
              <img 
                src={starLogo} 
                alt="Menu" 
                onClick={toggleSidebar}
                className="sidebar-menu-icon"
              />
              {isOpen && (
                <img 
                  src={namelogo} 
                  alt="Jobmaker" 
                  onClick={() => navigate('/main')} 
                  style={{ cursor: 'pointer', height: '30px', marginLeft: '10px' }}
                />
              )}
            </div>
            <ul className="sidebar-menu">
              <li>
                <Link to="/main" className={isActive('/main') ? 'active' : ''}>
                  <FaHome style={{ color: getIconColor('/main') }} />
                  {isOpen && <span>홈</span>}
                </Link>
              </li>
              <li>
                <div 
                  className={`sidebar-menu-item ${isActive('/tools') ? 'active' : ''}`} 
                  onClick={handleToolsClick}
                >
                  <FaTools style={{ color: getIconColor('/tools') }} />
                  {isOpen && <span>취업도구</span>}
                  {isOpen && (isToolsOpen ? <FaChevronUp /> : <FaChevronDown />)}
                </div>
                {isToolsOpen && (
                  <ul className="sidebar-submenu">
                    <li>
                      <Link 
                        to="/tools/resume-builder" 
                        className={location.pathname === '/tools/resume-builder' ? 'active' : ''}
                      >
                        <FaFileAlt />
                        {isOpen && <span>이력서 생성기</span>}
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to="/tools/resume-generator/setinfo"
                        className={location.pathname.startsWith('/tools/resume-generator') ? 'active' : ''}
                      >
                        <FaPencilAlt />
                        {isOpen && <span>자소서 생성기</span>}
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to="/tools/resume-evaluation"
                        className={location.pathname === '/tools/resume-evaluation' ? 'active' : ''}
                      >
                        <FaCheckSquare />
                        {isOpen && <span>자소서 평가</span>}
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to="/tools/photo-generator"
                        className={location.pathname === '/tools/photo-generator' ? 'active' : ''}
                      >
                        <FaCamera />
                        {isOpen && <span>증명사진 생성기</span>}
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to="/tools/questions-setInfo"
                        className={location.pathname === '/tools/questions-setInfo' ? 'active' : ''}
                      >
                        <FaQuestionCircle />
                        {isOpen && <span>면접예상질문 생성기</span>}
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to="/tools/tech-tree-generator"
                        className={location.pathname === '/tools/tech-tree-generator' ? 'active' : ''}
                      >
                        <FaProjectDiagram />
                        {isOpen && <span>테크트리 생성기</span>}
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to="/tools/job-matching"
                        className={location.pathname === '/tools/job-matching' ? 'active' : ''}
                      >
                        <FaHandshake />
                        {isOpen && <span>잡 매칭</span>}
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to="/tools/ai-interviewer"
                        className={location.pathname === '/tools/ai-interviewer' ? 'active' : ''}
                      >
                        <FaUserTie />
                        {isOpen && <span>AI 면접관</span>}
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <Link to="/recruit/info" className={isActive('/recruit') ? 'active' : ''}>
                  <FaSearch style={{ color: getIconColor('/recruit') }} />
                  {isOpen && <span>채용정보</span>}
                </Link>
              </li>
              <li>
                <Link to="/community" className={isActive('/community') ? 'active' : ''}>
                  <FaComments style={{ color: getIconColor('/community') }} />
                  {isOpen && <span>커뮤니티</span>}
                </Link>
              </li>
              <li>
                <div 
                  className={`sidebar-menu-item ${isPopupOpen ? 'active' : ''}`} 
                  onClick={togglePopup}
                >
                  <FaBell style={{ color: isPopupOpen ? '#2E74FF' : 'white' }} />
                  {isOpen && <span>나의 알림</span>}
                </div>
              </li>
            </ul>
            <div className="sidebar-user-info">
              {isLoggedIn ? (
                <>
                  <div className="sidebar-username">
                    <FaUser />
                    <span>{username} 님</span>
                  </div>
                  <div className={`sidebar-tokens ${location.pathname === '/payment' ? 'active' : ''}`} onClick={handleTokenClick}>
                    <FaCoins />
                    <span>{tokens}</span>
                  </div>
                  <div className="sidebar-settings">
                    <Link 
                      to="/mypage" 
                      className={`sidebar-mypage-btn ${location.pathname === '/mypage' ? 'active' : ''}`}
                    >
                      <FaUserCog />
                      {isOpen && <span>마이페이지</span>}
                    </Link>
                    <button className="sidebar-logout-btn" onClick={handleLogout}>
                      <FaSignOutAlt />
                      {isOpen && <span>로그아웃</span>}
                    </button>
                  </div>
                </>
              ) : (
                <button className={`sidebar-login-btn ${location.pathname === '/login' ? 'active' : ''}`} onClick={() => navigate('/login')}>
                  <FaSignInAlt />
                  {isOpen && <span>로그인하여 취직하러가기!</span>}
                </button>
              )}
            </div>
            {/* PC 버전 알림 팝업 */}
            {!isMobile && isPopupOpen && <AlertPopup onClose={togglePopup} />}
          </>
        )}
      </div>

      {/* 모바일 상단 헤더 */}
      {isMobile && (
        <div className="mobile-header">
          <div className="mobile-header-content">
            <img 
              src={namelogo} 
              alt="Jobmaker" 
              className="mobile-header-logo"
              onClick={() => navigate('/main')}
              style={{ cursor: 'pointer' }}
            />
            <div 
              className="mobile-header-notification"
              onClick={togglePopup}
            >
              <FaBell />
            </div>
          </div>
        </div>
      )}

      {/* 모바일 버전 알림 팝업 */}
      {isMobile && isPopupOpen && (
        <>
          <div className="mobile-notification-overlay" onClick={togglePopup} />
          <div className="mobile-notification-popup">
            <div className="mobile-notification-header">
              <h3>알림</h3>
              <button onClick={togglePopup}>
                <FaTimes />
              </button>
            </div>
            <div className="mobile-notification-content">
              <div className="notification-item">
                <div className="notification-title">새로운 채용 공고</div>
                <div className="notification-time">방금 전</div>
                <div className="notification-message">
                  관심 기업 '카카오'에서 새로운 채용 공고를 등록했습니다.
                </div>
              </div>
              <div className="notification-item">
                <div className="notification-title">자기소개서 첨삭 완료</div>
                <div className="notification-time">1시간 전</div>
                <div className="notification-message">
                  요청하신 자기소개서 첨삭이 완료되었습니다.
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* 모바일 버전 네비게이션 바 */}
      {isMobile && (
        <>
          <nav className="mobile-navigation">
            <ul className="mobile-nav-menu">
              {mobileMenuItems.map((item, index) => (
                <li key={index}>
                  {item.path ? (
                    <Link 
                      to={item.path} 
                      className={`mobile-nav-item ${location.pathname === item.path ? 'active' : ''}`}
                    >
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  ) : (
                    <div 
                      className={`mobile-nav-item ${isProfileOpen ? 'active' : ''}`} 
                      onClick={item.onClick}
                    >
                      {item.icon}
                      <span>{item.title}</span>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </nav>

          {/* 모바일 프로필 드로어 */}
          <div className={`mobile-profile-drawer ${isProfileOpen ? 'open' : ''}`}>
            <div className="mobile-profile-header">
              <div className="mobile-profile-info">
                {isLoggedIn ? (
                  <>
                    <span>{username} 님</span>
                    <div className="sidebar-tokens" onClick={handleTokenClick}>
                      <FaCoins /> {tokens}
                    </div>
                  </>
                ) : (
                  <span>로그인이 필요합니다</span>
                )}
              </div>
            </div>
            <div className="mobile-profile-actions">
              {isLoggedIn ? (
                <>
                  <Link to="/mypage" className="sidebar-mypage-btn">
                    <FaUserCog /> 마이페이지
                  </Link>
                  <button className="sidebar-logout-btn" onClick={handleLogout}>
                    <FaSignOutAlt /> 로그아웃
                  </button>
                </>
              ) : (
                <button className="sidebar-login-btn" onClick={() => navigate('/login')}>
                  <FaSignInAlt /> 로그인하기
                </button>
              )}
            </div>
          </div>

          {/* 모바일 도구 서브메뉴 드로어 */}
          <div className={`mobile-tools-drawer ${isToolsDrawerOpen ? 'open' : ''}`}>
            <div className="mobile-tools-list">
              {mobileMenuItems.find(item => item.title === "도구").subMenu.map((tool, index) => (
                <Link 
                  key={index}
                  to={tool.path}
                  className={location.pathname === tool.path ? 'active' : ''}
                  onClick={() => setIsToolsDrawerOpen(false)}
                >
                  {tool.icon}
                  <span>{tool.title}</span>
                </Link>
              ))}
            </div>
          </div>

          {/* 드로어 오버레이 */}
          <div 
            className={`mobile-drawer-overlay ${isToolsDrawerOpen || isProfileOpen ? 'visible' : ''}`}
            onClick={() => {
              setIsToolsDrawerOpen(false);
              setIsProfileOpen(false);
            }}
          />
        </>
      )}
    </>
  );
};

export default Sidebar;
