import React, { useState } from 'react';
import PhotoUploadStep from './PhotoUploadStep';
import OptionsStep from './OptionsStep';
import ResultStep from './ResultStep';
import ConfirmStep from './ConfirmStep';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import './PhotoGeneratorPage.css';

const PhotoGeneratorPage = () => {
  const [step, setStep] = useState(1);
  const [mainPhoto, setMainPhoto] = useState(null);
  const [gender, setGender] = useState('');
  const [style, setStyle] = useState('');
  const [generatedPhotos, setGeneratedPhotos] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleGeneratePhotos = async () => {
    const token = getToken();
    setLoading(true);
    const formData = new FormData();
    
    try {
      if (!mainPhoto || !style || !gender) {
        throw new Error('모든 필수 항목을 선택해주세요.');
      }
      
      formData.append('faceImage', mainPhoto);
      formData.append('gender', gender);
      formData.append('style', style);

      const response = await axios.post('http://localhost:3001/photos/generate', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': token,
        },
      });
      
      if (!response.data.photos || response.data.photos.length === 0) {
        throw new Error('생성된 사진이 없습니다.');
      }

      setGeneratedPhotos(response.data.photos);
      handleNextStep();
    } catch (error) {
      console.error('Error generating photos:', error);
      alert(error.response?.data?.error || error.message || '사진 생성 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="photo-generator-container">
      {step === 1 && (
        <OptionsStep
          style={style}
          setStyle={setStyle}
          handleNextStep={handleNextStep}
        />
      )}
      {step === 2 && (
        <PhotoUploadStep
          mainPhoto={mainPhoto}
          setMainPhoto={setMainPhoto}
          gender={gender}
          setGender={setGender}
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
        />
      )}
      {step === 3 && (
        <ConfirmStep
          style={style}
          gender={gender}
          mainPhoto={mainPhoto}
          handleGeneratePhotos={handleGeneratePhotos}
          handlePrevStep={handlePrevStep}
          loading={loading}
        />
      )}
      {step === 4 && (
        <ResultStep
          generatedPhotos={generatedPhotos}
          onBack={handlePrevStep}
        />
      )}
    </div>
  );
};

export default PhotoGeneratorPage;
