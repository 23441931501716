import React from 'react';
import { FaUpload, FaTrashAlt, FaMale, FaFemale, FaTimes } from 'react-icons/fa';
import { ToastContainer } from 'react-toastify';
import ImageGuide from './ImageGuide';
import './PhotoUploadStep.css';
import { IoClose, IoCheckmark } from 'react-icons/io5';

const PhotoUploadStep = ({ 
  mainPhoto, 
  setMainPhoto, 
  gender, 
  setGender, 
  handleNextStep, 
  handlePrevStep 
}) => {
  const handleMainPhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // 이미지 파일 검증
      if (!file.type.startsWith('image/')) {
        alert('이미지 파일만 업로드 가능합니다.');
        return;
      }
      
      // 파일 크기 제한 (10MB)
      if (file.size > 10 * 1024 * 1024) {
        alert('파일 크기는 10MB 이하여야 합니다.');
        return;
      }

      setMainPhoto(file);
    }
  };

  const removeMainPhoto = () => {
    setMainPhoto(null);
  };

  const handleContinue = () => {
    if (!mainPhoto) {
      alert('사진을 업로드해주세요.');
      return;
    }
    if (!gender) {
      alert('성별을 선택해주세요.');
      return;
    }
    handleNextStep();
  };

  return (
    <div className="photo-upload-step">
      <ToastContainer />
      <h1 className="photo-upload-step__title">증명사진 생성기</h1>
      <h2 className="photo-upload-step__subtitle">사진 업로드 (1/2 Step)</h2>

      <div className="photo-upload-step__content">
        <div className="photo-upload-step__section-container">
          <ImageGuide />
        </div>

        <div className="photo-upload-step__section-container">
          <div className="photo-upload-step__gender-section">
            <h3 className="photo-upload-step__section-title">
              성별 <span className="photo-upload-step__required">*</span>
            </h3>
            <div className="photo-upload-step__gender-options">
              <div 
                className={`photo-upload-step__gender-option ${gender === 'male' ? 'selected' : ''}`}
                onClick={() => setGender('male')}
              >
                <FaMale className="photo-upload-step__gender-icon" />
                <span>남성</span>
              </div>
              <div 
                className={`photo-upload-step__gender-option ${gender === 'female' ? 'selected' : ''}`}
                onClick={() => setGender('female')}
              >
                <FaFemale className="photo-upload-step__gender-icon" />
                <span>여성</span>
              </div>
            </div>
          </div>
        </div>

        <div className="photo-upload-step__section-container">
          <div className="photo-upload-step__main">
            <div className="photo-upload-step__upload-section">
              <h3 className="photo-upload-step__section-title">
                정면 사진 1장 <span className="photo-upload-step__required">*</span>
              </h3>
              <div className="photo-upload-step__upload-area">
                {mainPhoto ? (
                  <div className="photo-upload-step__preview">
                    <img 
                      src={URL.createObjectURL(mainPhoto)} 
                      alt="업로드된 사진"
                      className="photo-upload-step__preview-image" 
                    />
                    <button 
                      onClick={removeMainPhoto}
                      className="photo-upload-step__remove-button"
                      aria-label="사진 삭제"
                    >
                      <FaTrashAlt />
                    </button>
                  </div>
                ) : (
                  <>
                    <input
                      type="file"
                      id="mainPhotoUpload"
                      className="photo-upload-step__input-file"
                      accept="image/*"
                      onChange={handleMainPhotoUpload}
                    />
                    <label 
                      htmlFor="mainPhotoUpload" 
                      className="photo-upload-step__upload-label"
                    >
                      <div className="photo-upload-step__upload-icon">
                        <FaUpload />
                      </div>
                      <span>업로드</span>
                    </label>
                  </>
                )}
                <div className="photo-upload-step__file-info">
                  jpg, jpeg, png
                </div>
              </div>
            </div>

            <div className="photo-upload-step__additional-section">
              <h3 className="photo-upload-step__section-title">
                필터링 향상을 위한 추가 사진 1~5장
              </h3>
              <div className="photo-upload-step__upload-area">
                <input
                  type="file"
                  id="additionalPhotos"
                  className="photo-upload-step__input-file"
                  accept="image/*"
                  multiple
                />
                <label 
                  htmlFor="additionalPhotos" 
                  className="photo-upload-step__upload-label"
                >
                  <div className="photo-upload-step__upload-icon">
                    <FaUpload />
                  </div>
                  <span>업로드</span>
                </label>
                <div className="photo-upload-step__file-info">
                  jpg, jpeg, png
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="step-buttons">
          <button 
            className="step-button step-button--secondary"
            onClick={handlePrevStep}
          >
            이전
          </button>
          <button
            className="step-button step-button--primary"
            onClick={handleContinue}
            disabled={!mainPhoto || !gender}
          >
            다음
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhotoUploadStep;