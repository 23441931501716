import React from 'react';
import SpinningLogo from '../../../components/SpinningLogo';
import './ConfirmStep.css';

const ConfirmStep = ({ 
  style, 
  gender, 
  mainPhoto, 
  handleGeneratePhotos, 
  handlePrevStep, 
  loading 
}) => {
  return (
    <div className="confirm-step">
      {loading ? (
        <SpinningLogo text="사진을 생성하고 있습니다..." />
      ) : (
        <>
          <h1 className="confirm-step__title">증명사진 생성기</h1>
          <h2 className="confirm-step__subtitle">증명사진 4장 생성</h2>

          <div className="confirm-step__content">
            <div className="confirm-step__info">
              <div className="confirm-step__photo">
                {mainPhoto && (
                  <img 
                    src={URL.createObjectURL(mainPhoto)} 
                    alt="업로드된 사진" 
                  />
                )}
              </div>
              
              <div className="confirm-step__details">
                <div className="confirm-step__detail-item">
                  <span className="confirm-step__detail-label">스타일</span>
                  <span className="confirm-step__detail-value">
                    <span className="confirm-step__detail-badge">{style}</span>
                  </span>
                </div>
                
                <div className="confirm-step__detail-item">
                  <span className="confirm-step__detail-label">성별</span>
                  <span className="confirm-step__detail-value">
                    <span className="confirm-step__detail-badge">
                      {gender === 'male' ? '남성' : '여성'}
                    </span>
                  </span>
                </div>

                <div className="confirm-step__detail-item">
                  <span className="confirm-step__detail-label">업로드한 사진</span>
                  <span className="confirm-step__detail-value confirm-step__detail-value--highlight">
                    필수 1장
                  </span>
                </div>

                <div className="confirm-step__detail-item">
                  <span className="confirm-step__detail-label">추가 1~5장</span>
                  <span className="confirm-step__detail-value confirm-step__detail-value--upload">
                    추가 사진 업로드
                  </span>
                </div>
              </div>
            </div>

            <div className="confirm-step__reference-photos">
              <h3 className="confirm-step__reference-title">참고 사진</h3>
              <div className="confirm-step__photo-grid">
                {/* 여기에 참고 사진들을 매핑 */}
                {Array(6).fill(null).map((_, index) => (
                  <div key={index} className="confirm-step__reference-item">
                    {mainPhoto && (
                      <img 
                        src={URL.createObjectURL(mainPhoto)} 
                        alt={`참고 사진 ${index + 1}`} 
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="step-buttons">
              <button 
                className="step-button step-button--secondary"
                onClick={handlePrevStep}
                disabled={loading}
              >
                이전
              </button>
              <button
                className="step-button step-button--primary"
                onClick={handleGeneratePhotos}
                disabled={loading}
              >
                사진 생성
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ConfirmStep; 