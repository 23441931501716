import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getToken } from '../../utils/auth';
import styled from 'styled-components';
import SpinningLogo from '../SpinningLogo';

const InterviewResult = () => {
  const { interviewId } = useParams();
  const navigate = useNavigate();
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResult = async () => {
      try {
        const token = getToken();
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/interview/results/${interviewId}`,
          {
            headers: {
              'x-auth-token': token
            }
          }
        );

        if (response.data.success) {
          setResult(response.data.data);
        }
      } catch (error) {
        console.error('결과 조회 실패:', error);
        setError('면접 결과를 불러오는데 실패했습니다.');
      } finally {
        setLoading(false);
      }
    };

    fetchResult();
  }, [interviewId]);

  if (loading) {
    return (
      <LoadingContainer>
        <SpinningLogo text="면접 결과를 분석하고 있습니다..." />
      </LoadingContainer>
    );
  }

  if (error) {
    return <Message>{error}</Message>;
  }

  return (
    <ResultContainer>
      <Title>면접 결과 분석</Title>
      
      <ScoreSection>
        <h2>종합 평가 점수</h2>
        <ScoreDisplay>{result?.overallScore || 0}/100</ScoreDisplay>
      </ScoreSection>

      <SkillGrid>
        <SkillCard>
          <h3>기술 역량</h3>
          <Score>{result?.technicalEvaluation?.score || 0}/100</Score>
          <p>{result?.technicalEvaluation?.feedback || '평가 내용이 없습니다.'}</p>
        </SkillCard>

        <SkillCard>
          <h3>커뮤니케이션</h3>
          <Score>{result?.communicationEvaluation?.score || 0}/100</Score>
          <p>{result?.communicationEvaluation?.feedback || '평가 내용이 없습니다.'}</p>
        </SkillCard>
      </SkillGrid>

      <FeedbackSection>
        <h3>주요 강점</h3>
        <StrengthsList>
          {(result?.strengths || []).map((strength, index) => (
            <li key={index}>{strength}</li>
          ))}
        </StrengthsList>

        <h3>개선이 필요한 부분</h3>
        <ImprovementList>
          {(result?.improvements || []).map((improvement, index) => (
            <li key={index}>{improvement}</li>
          ))}
        </ImprovementList>
      </FeedbackSection>

      <ButtonContainer>
        <Button onClick={() => navigate('/mypage')}>
          마이페이지로 이동
        </Button>
        <Button onClick={() => navigate('/tools/ai-interviewer')}>
          새로운 면접 시작
        </Button>
      </ButtonContainer>
    </ResultContainer>
  );
};

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin: 2rem;
  background-color: #fff3f3;
  border-radius: 8px;
  color: #e74c3c;
  font-size: 1.1rem;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
`;

const Message = styled.div`
  text-align: center;
  color: #e74c3c;
  padding: 20px;
  margin: 20px;
  font-size: 1.2rem;
`;

const ResultContainer = styled.div`
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 40px;
  color: #333;
`;

const ScoreSection = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const ScoreDisplay = styled.div`
  font-size: 3rem;
  font-weight: bold;
  color: #2c3e50;
  margin: 20px 0;
`;

const EvaluationSection = styled.div`
  margin-bottom: 40px;
`;

const SkillGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

const SkillCard = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Score = styled.div`
  font-size: 2rem;
  color: #2c3e50;
  margin: 10px 0;
`;

const StrengthsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
`;

const FeedbackSection = styled.div`
  margin-bottom: 40px;
`;

const ImprovementList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
`;

const Button = styled.button`
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  background-color: #3498db;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2980b9;
  }
`;

export default InterviewResult; 